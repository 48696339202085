/* Section-1 */
.all-page-padding {
  padding: 0 10px;
}
.wavebgbanner {
  background-image: url(../images/wavebgbanner.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  /* height: 80vh; */
  width: 100%;
}
.d-grid-home-banner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 50px 10px;
}
.pure .health {
  /* max-width: 533px;
  min-width: 250px; */
  width: 533px;

  /* height: 227px; */
  /* border: 1px solid black; */
}
.pure .health .under-impo {
  font-family: Roboto-Bold;
  font-weight: 400;
  font-size: 18px;
  line-height: 55px;
  text-align: left;
  /* border: 1px solid black; */
}

.pure .health .drinking-water {
  font-family: PlayfairDisplay-Bold;
  font-weight: 700;
  font-size: 48px;
  /* line-height: 58px; */
  text-align: left;
  /* border: 1px solid black; */
}
 .drinking-waters{
  font-family: PlayfairDisplay-Bold;
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    text-align: left;
}
.banner-inquery-button {
  /* width: 200px; */
  /* padding: 20px 40px; */
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
  border: none;
  cursor: pointer;

  text-align: left;

  padding: 5px 40px;
  border-radius: 356px;
  background: black;
  color: white;
  z-index: 0;
}

.banner-inquery-button:hover .wave {
  top: -120px;
}

.text {
  position: relative;
  z-index: 1;
  color: white;

  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.more-product-button {
  text-align: -webkit-center;
  padding: 0 0 25px 0;
}
.wave {
  width: 231px;
  height: 200px;
  background-color: #0096ea;
  position: absolute;
  left: 0;
  top: -83px;
  transition: 2.4s;
}

.wave::before,
a .wave::after {
  width: 200%;
  height: 200%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wave::before {
  border-radius: 45%;
  background-color: black;
  animation: wave 10s linear infinite;
}

.wave::after {
  border-radius: 45%;
  background-color: black;
  animation: wave 10s linear infinite;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(300deg);
  }
}
/* .banner-inquery-button {
  text-align: left;
  padding-top: 40px;
}
.banner-inquery-button button{
  padding: 5px 22px;
border-radius: 356px;
background: black;
color: white;

font-family:  Roboto-Bold;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
} */
.bannerimage {
  /* border: 1px solid black; */
}
.image {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
}
.bannerimage img {
  max-width: 604px;
  min-width: 250px;
  width: 100%;
}

/* Section-2 */
.d-grid-homa-bout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 50px 10px;
}
.d-grid-homa-bout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px 10px;
}
.all-heading {
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 48px;
  /* line-height: 58.59px; */
  text-align: center;
  color: black;
  padding-bottom: 25px;
  /* border: 1px solid black; */
}
.filter-flonix {
  /* border:1px solid black ; */

  max-width: 573px;
  min-width: 200px;
  width: 100%;
}
.about-choosing .filter-flonix .filter-heading {
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 40px;
  line-height: 59px;
  text-align: left;
  color: black;
}
.about-choosing .filter-flonix .filter-peragraph {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: justify;
  color: black;
  padding: 10px 0 5px 0;
}
.benifts li {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  text-align: left;
  list-style: none;
  color: black;
}
.benifts li::before {
  content: "\1F5F8";
  font-weight: 900;
  color: white;
  font-size: 14px;
  margin-right: 15px;
  background: black;
  border-radius: 50%;
  padding: 2px 6px;
}
:root {
  --primary-color: #111;
  --hovered-color: #0096ea;
}
.buttonclass {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  /* z-index: -1; */
}

.buttonclass {
  /* --primary-color: #111;
    --hovered-color: #c84747; */
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.buttonclass p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 80px;
  margin: 0;
  position: relative;
  color: var(--primary-color);
  z-index: -999;
}

.buttonclass:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.buttonclass svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  z-index: -999;
}
/* .buttonclass:hover p{
  color: var(--hovered-color);
  transition: 0.2s;
  transition-delay: 0.2s;
  z-index: -999;
} */

.aboutimage {
  max-width: 598px;
  min-width: 200px;
  width: 100%;
  /* height: 496px; */
  box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
  border-radius: 15px;
}
.aboutimage iframe {
  max-width: 598px;
  min-width: 200px;
  width: 100%;
  height: 496px;
  /* box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px; */
  border-radius: 15px;
}

/* Section-3 */
.card-3 {
  padding: 0 10px;
}
.top-product {
  margin: 20px auto;
  /* gap: 20px; */

  max-width: 399px;
  min-width: 100px;
  width: 100%;
  /* height: 400px; */
  box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
  border-radius: 20px;
}
.top-product img {
  max-width: 399px;
  min-width: 100px;
  width: 100%;
  /* height: 400px; */
  border-radius: 20px 20px 0 0;
}
.top-product p {
  /* padding: 0 0 20px 0; */
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 20px;
  color: black;
  /* line-height: 40px; */
  height: 40px;
}

/* .top-product button {
  
  padding: 5px 22px;
  border-radius: 356px;
  background: black;
  color: white;

  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  margin: 20px 0 20px 0;
  border: none;
  cursor: pointer;
}

.top-product button:hover {
  background: #0096ea;
  transition: all 0.5s ease;
} */

/* section-4 */
.bg .border-1 {
  position: relative;
  left: 0px;
  top: -20px;
  width: 100%;
  height: 49px;
  background-repeat: repeat-x;
  /* animation: slide 400s linear infinite;
  -webkit-animation: slide 400s linear infinite; */
  background-image: url(../images/border1.svg);
  /* border: 1px solid black; */
  animation: moveBackground 5s linear infinite;
}
@keyframes moveBackground {
  from {
    background-position: -100% 0; /* Starting position */
  }
  to {
    background-position: 0 0; /* Ending position */
  }
}
.under1213width {
  max-width: 1213.74px;
  min-width: 250px;
  margin: 0 auto;
}
.d-flex-water-purefier {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.why-use-water-purifier {
  height: 467px;
  /* border: 1px solid black; */
  /* width: 325px; */
  max-width: 325px;
  min-width: 200px;
  width: 100%;
}

.why-use {
  display: flex;
  align-items: center;
  height: 50%;
  justify-content: space-between;
}

.water-puri-container {
  width: 260.73px;
  /* border: 1px solid black; */
  text-align: right;
  height: 35%;
}
.water-puri-slash p {
  font-size: 10px;
  font-weight: bolder;
}
.water-purefire-boxes {
  display: flex;
  /* height: 1200px; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.water-purefire {
  position: sticky;
  overflow: hidden;
}
.water-purefire img {
  /* max-width: 515.46px;
  min-width: 250px; */
  width: 515px;
  /* height: 550px; */
  position: relative;
  z-index: 1;
}
.water-purefire .water-ro {
  position: absolute;
  top: 120px;
  left: 0;
  opacity: 1;
  z-index: 0;
  /* max-width: 515.46px;
  min-width: 250px; */
  width: 515px;
  /* padding-top: 74px; */
}
.water-one-line {
  margin: 30px auto;
  /* width: 842px; */
  max-width: 842px;
  min-width: 200px;
  /* border: 1px solid black; */
}
.water-flex-line {
  display: flex;
  /* flex-wrap: wrap; */
  /* text-align: center; */
  justify-content: space-between;
  align-items: center;
}
.water-under-flex-line {
  display: flex;
}
.tilu {
  padding: 0 0 0 10px;
}

.water-heading {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 20px; */
}
.water-pera {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 30px; */
  /* align-items: center; */
}

/* section-5 */
.d-flex-hexagoan-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px 40px;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 3s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
.hexagon-box {
  height: 210px;
  z-index: -999;
}
.hexagon-wrap {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  filter: drop-shadow(0px 2px 10px rgba(39, 170, 243, 0.25));
}

.hexagon-rectangle {
  width: 182px;
  height: 158px;
  position: relative;
  background: white;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.inner-rectangle {
  position: relative;
  top: 25%;
  left: 0;
  width: 100%;
  /* background-color: inherit; */
}
.hexagon-box-pera p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 80px; */
  align-items: center;
  padding: 20px 0;
}

/* section-6 */
.our-product-button button {
  padding: 10px 22px;
  border-radius: 356px;
  background: black;
  color: white;

  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  margin: 50px 0;
  border: none;
  cursor: pointer;
}
.our-product-button :hover {
  background: #0096ea;
  transition: all 0.5s ease;
}

/* section-7 */
.d-flex-circle-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 40px 40px;
}

.our-distributor-main-heading {
  max-width: 639px;
  width: 100%;
  min-width: 250px;
  margin: 20px auto;
}

.our-distributor-main-heading p {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  align-items: center;
  color: black;
}

.shadow-circle-box {
  filter: drop-shadow(0px 2px 10px rgba(39, 170, 243, 0.25));
  z-index: -999;
}
.circle-box {
  width: 185px;
  height: 185px;
  background: white;
  border-radius: 50%;
  /* border: 1px solid black; */
}
.inner-circle {
  position: relative;
  top: 6%;
  left: 0;
  width: 100%;
}
.inner-circle img {
  width: 165px;
  height: 165px;
}

/* section-8 */
.under1120width {
  max-width: 1120px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
}
.testo-next-arrow {
  position: absolute;
  top: 50%;
  right: -5%;
  border-radius: 50%;
  box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
  width: 56px;
  height: 56px;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}
.testo-arrow-back {
  position: absolute;
  top: 50%;
  left: -5%;
  border-radius: 50%;
  box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
  width: 56px;
  height: 56px;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}
/* .slider-upper-padding{
  padding: 10px 0;
} */

.testimonial-width {
  max-width: 1056px;
  min-width: 250px;
  width: 100%;
  /* border: 1px solid black; */
  margin: 20px auto;
  padding: 90px 0;
  background: white;
  /* filter: drop-shadow(0px 2px 10px rgba(39, 170, 243, 0.25)); */
  box-shadow: 0px 0px 28.8px 5px #cdedf940;

  border-radius: 15px;
  position: relative;
  z-index: -999;
  /* height: 438px; */
}
.testimonial-under-width {
  max-width: 945.45px;
  min-width: 250px;
  width: 100%;
  /* height: 263px; */
  /* border: 1px solid black; */
  margin: 0px auto;
  z-index: -999;
  /* top: 22%; */
  /* position: relative; */
}
.testimonial-display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.testimonial-name-heading-pera-width {
  max-width: 615px;
  min-width: 100px;
  width: 100%;
  gap: 25px;
  /* border: 1px solid black; */
  text-align: left;
}

.testimonial-name {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
}
.testimonial-designation {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  margin: 25px 0;
}
.testimonial-review {
  background: #f2faff;
  padding: 20px 4px;
  border-radius: 10px;
  /* style={{padding:"20px 0",background:"blue",color:"black",border:"1px solid black",textAlign:"left"}} */
}
.testimonial-review p {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  /* margin: 35px 0; */
}

/* .shadow-testimonial-box {
  filter: drop-shadow(0px 2px 10px rgba(99, 99, 99, 0.2));
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} */
.testimonial-box {
  width: 235px;
  height: 236px;
  background: white;
  border-radius: 50%;
  /* border: 1px solid black; */
}
.inner-testimonial {
  position: relative;
  top: 18%;
  /* left: 24%; */
  width: 100%;
}
.inner-testimonial img {
  width: 123px;
  height: 143px;
  position: relative;
  top: 18%;
  left: 0%;
  width: 100%;
}

/* section-9 */
.blog-box {
  max-width: 380px;
  min-width: 250px;
  width: 100%;
  /* height: 661px; */
  /* height: 600px; */
  gap: 90px;
  /* border: 1px solid black; */
  border-radius: 15px;
  box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
}
.blog-box .blog-imge {
  max-width: 380px;
  min-width: 250px;
  width: 100%;
  height: 249px;
  border-radius: 15px 15px 0 0;
  /* object-fit: cover; */
}
.blog-box-contents {
  max-width: 315px;
  min-width: 250px;
  width: 100%;
  /* height: 365px;  */
  /* border: 1px solid black; */
  margin: 23px auto;
  text-align: left;
}
.blog-under-box .blog-heading {
  font-family: Roboto-Medium;
  font-weight: 500;

  font-size: 20px;
  line-height: 28.13px;
  /* height: 50px; */
}
.blog-under-box .blog-service {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  padding: 10px 0 0 0;
}
.blog-under-box .blog-service span {
  color: #0096ea;
}
.blog-under-box .blog-pera {
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  margin: 20px 0;
}
.blog-stuff {
  margin: 20px 0;
}
.blog-stuff img {
  max-width: 313px;
  min-width: 200px;
  width: 100%;
}
.blog-people-clander {
  display: flex;
  padding: 15px 10px 0px 10px;
  text-align: left;
}
.blog-icon-div {
  width: 200px;
  /* border: 1px solid black */
}
.blog-icon-div p {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  text-align: justify;
}
.blog-icon-div p i {
  padding: 0 10px;
  color: #0096ea;
}
.blog-button {
  padding: 15px 0 20px 0;
}
.blog-button button {
  /* padding: 10px 25px; */
  background: black;
  color: white;
  font-family: Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  border-radius: 356px;
  cursor: pointer;
}

/* section-10 */

/* .inquiry-icon-width{
max-width:339.42px ;
min-width:279px ;
width:100% ;

}
.inquiry-icon-flex{
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
}
.form-address .form-heading{
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
}
.form-address .form-pera{
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px; 
  line-height: 21.09px; 
}


.inquiry-icon-flex-up-down-margin{
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;
  margin: 90px 0;
}

.form-width{
max-width: 800px;
min-width: 279px;
width: 100%;
}

.form-flex{
display: flex;
justify-content: space-between;
text-align: left;
gap: 59px;
}
.form-flex-up-down-margin{
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 59px;
  margin: 41.5px 0;
}
.form-label-input .form-label{
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px; 
  line-height: 40px; 

}
.form-label-input .form-inputs{
  width: 370px;
  max-width: 370px;
  min-width:279px ;
  
  border-radius: 15px;
  padding: 20px;
  border: 1px solid black;
}

.form-label-textarea .form-label{
  font-family: Roboto-Regular;
  font-weight: 400;
  font-size: 18px; 
  line-height: 40px; 
}

.form-label-textarea .form-textarea{
 max-width: 800px;
 min-width: 279px;
 width: 100%;
 padding: 40px 20px;

 border-radius: 15px;
 border: 1px solid black;
}
.form-button{
  margin: 50px 0 0 0;
  text-align: left;
}

.form-button button{
  background: black;
  color: white;
  padding: 15px 25px;
  border-radius: 365px;
  font-family:  Roboto-Bold;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  border: none;
} */

@media screen and (max-width: 1230px) {
  .testo-next-arrow {
    position: absolute;
    top: 50%;
    right: 1%;
    border-radius: 50%;
    box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
    width: 56px;
    height: 56px;
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    display: none;
  }
  .testo-arrow-back {
    position: absolute;
    top: 50%;
    left: 1%;
    border-radius: 50%;
    box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
    width: 56px;
    height: 56px;
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .all-page-padding {
    padding: 0 30px;
  }
  .d-grid-home-banner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 0px 10px;
  }

  .pure .health {
    max-width: 369px;
    min-width: 250px;
    width: 100%;
    /* height: 227px; */
    /* border: 1px solid black; */
    /* padding-top: 10px; */
  }
  .pure .health .under-impo {
    font-family: Roboto-Bold;
    font-weight: 400;
    font-size: 16px;
    line-height: 55px;
    text-align: left;
    /* border: 1px solid black; */
  }
  .pure .health .drinking-water {
    font-family: PlayfairDisplay-Bold;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;

    /* border: 1px solid black; */
  }
   .drinking-waters{
    font-family: PlayfairDisplay-Bold;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
  }

  .all-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 30px;
    /* line-height: 35px; */
    text-align: center;
    color: black;
    padding-bottom: 25px;
    /* border: 1px solid black; */
  }
  .d-grid-homa-bout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px 10px;
    text-align: center;
    flex-direction: column-reverse;
  }
  .filter-flonix {
    /* border:1px solid black ; */

    max-width: 964px;
    min-width: 200px;
    width: 100%;
  }
  .aboutimage {
    max-width: 964px;
    min-width: 200px;
    width: 100%;
    height: 496px;
    box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px;
    border-radius: 15px;
  }
  .aboutimage iframe {
    max-width: 964px;
    min-width: 200px;
    width: 100%;
    height: 496px;
    /* box-shadow: rgba(39, 170, 243, 0.25) 0px 2px 8px 0px; */
    border-radius: 15px;
  }
  .about-choosing .filter-flonix .filter-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: black;
  }
  .about-choosing .filter-flonix .filter-peragraph {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    color: black;
    padding: 20px 0;
  }
  .benifts li {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    text-align: left;
    list-style: none;
    color: black;
  }
  .top-product p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    color: black;
    /* line-height: 40px; */
  }
  .top-product button {
    padding: 5px 22px;
    border-radius: 356px;
    background: black;
    color: white;
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin: 20px 0 20px 0;
    border: none;
    cursor: pointer;
  }
  .d-flex-water-purefier {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    /* justify-content: space-between; */
    justify-items: center;
    gap: 20px 0px;
  }
  .why-use-water-purifier {
    height: 200px;
    /* border: 1px solid black; */
    /* width: 325px; */
    max-width: 964px;
    min-width: 250px;
    width: 100%;
  }
  .why-use {
    display: flex;
    align-items: center;
    height: 45%;
    justify-content: space-between;
  }

  .water-puri-slash p {
    font-size: 10px;
    font-weight: bolder;
    display: none;
  }
  .water-puri-container {
    width: 964px;
    /* border: 1px solid black; */
    text-align: right;
    height: 35%;
  }
  .water-heading {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  .water-pera {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    /* line-height: 30px; */
    /* align-items: center; */
  }
  .tilu img {
    display: none;
  }

  .water-flex-line {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    justify-content: space-between;

    justify-items: center;
    gap: 20px 0px;
  }

  .testimonial-width {
    max-width: 1056px;
    min-width: 250px;
    width: 100%;
    /* border: 1px solid black; */
    margin: 0 auto;
    padding: 20px 0;
    background: white;
    box-shadow: 0px 0px 28.8px 5px #cdedf940;
    border-radius: 15px;
    position: relative;
    z-index: -999;
  }
  .testo-next-arrow {
    position: absolute;
    top: 50%;
    right: 1%;
    border-radius: 50%;
    box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
    width: 56px;
    height: 56px;
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    display: none;
  }
  .testo-arrow-back {
    position: absolute;
    top: 50%;
    left: 1%;
    border-radius: 50%;
    box-shadow: rgba(39, 170, 243, 0.35) 0px 5px 15px;
    width: 56px;
    height: 56px;
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    display: none;
  }
  .testimonial-display-flex {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    /* justify-content: space-between; */
    justify-items: center;
    gap: 20px 0px;
  }
  .testimonial-name-heading-pera-width {
    max-width: 615px;
    min-width: 100px;
    width: 100%;
    gap: 25px;
    /* border: 1px solid black; */
    text-align: left;
    order: 2;
  }
  .shadow-testimonial-box {
    /* filter: drop-shadow(0px 2px 10px rgba(99, 99, 99, 0.2)); */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    order: 1;
  }
  .testimonial-box {
    width: 235px;
    height: 236px;
    background: white;
    border-radius: 50%;
    /* border: 1px solid black; */
  }
  .inner-testimonial {
    position: relative;
    top: 18%;
    /* left: 24%; */
    width: 100%;
  }
  .inner-testimonial img {
    width: 123px;
    height: 143px;
    position: relative;
    top: 18%;
    left: 0%;
    width: 100%;
  }
  .testimonial-name {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.44px;
    text-align: center;
  }
  .testimonial-designation {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    margin: 25px 0;
    text-align: center;
  }
  .testimonial-review p {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    /* margin: 35px 0; */
  }
  .d-flex-hexagoan-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    gap: 40px 40px;
  }

  .d-flex-circle-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 40px 40px;
  }
  .hexagon-box-pera p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 80px; */
    align-items: center;
    padding: 20px 0;
  }
  .our-distributor-main-heading p {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: black;
  }
  .banner-inquery-button {
    /* width: 200px; */
    /* padding: 20px 40px; */
    position: relative;
    display: block;
    text-decoration: none;
    overflow: hidden;
    border: none;
    cursor: pointer;

    text-align: center;

    padding: 3px 28px;
    border-radius: 356px;
    background: black;
    color: white;
  }
  .text {
    position: relative;
    z-index: 1;
    color: white;

    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 14px;
    line-height: 28.77px;
    text-align: center;
    cursor: pointer;
  }
  .wave {
    width: 200px;
    height: 200px;
    background-color: #0096ea;
    position: absolute;
    left: 0;
    top: -75px;
    transition: 2.4s;
  }
  .wave::before,
  a .wave::after {
    width: 200%;
    height: 200%;
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 767px) {
  .all-page-padding {
    padding: 0 15px;
  }
  .pure .health .under-impo {
    font-family: Roboto-Bold;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    /* border: 1px solid black; */
  }
  .pure .health {
    /* max-width: 181px;
    min-width: 100px; */
    width: 100%;
    /* height: 227px; */
    /* border: 1px solid black; */
    padding-top: 10px;
  }
  .pure .health .drinking-water {
    font-family: PlayfairDisplay-Bold;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;

    /* border: 1px solid black; */
  }
   .drinking-waters{
    font-family: PlayfairDisplay-Bold;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  
  .banner-inquery-button button {
    padding: 5px 22px;
    border-radius: 356px;
    background: black;
    color: white;
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
  }
  .all-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 25px;
    /* line-height: 35px; */
    text-align: center;
    color: black;
    padding-bottom: 25px;
    /* border: 1px solid black; */
  }
  .about-choosing .filter-flonix .filter-heading {
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: black;
  }
  .about-choosing .filter-flonix .filter-peragraph {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: black;
    padding: 20px 0;
  }
  .benifts li {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    text-align: left;
    list-style: none;
    color: black;
  }
  .top-product p {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 14px;
    color: black;
    /* line-height: 40px; */
  }
  .top-product button {
    padding: 5px 22px;
    border-radius: 356px;
    background: black;
    color: white;
    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    margin: 20px 0 20px 0;
    border: none;
    cursor: pointer;
  }
  .water-flex-line {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    justify-content: space-between;

    justify-items: center;
    gap: 20px 0px;
  }
  .water-heading {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
  .water-pera {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    /* line-height: 30px; */
    /* align-items: center; */
  }
  .our-distributor-main-heading p {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: black;
  }
  .testimonial-name {
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    text-align: center;
  }
  .testimonial-designation {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    margin: 25px 0;
    text-align: center;
  }
  .testimonial-review p {
    font-family: Roboto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    /* margin: 35px 0; */
  }
  .banner-inquery-button {
    /* width: 200px; */
    /* padding: 20px 40px; */
    position: relative;
    display: block;
    text-decoration: none;
    overflow: hidden;
    border: none;
    cursor: pointer;

    text-align: left;

    padding: 6px 16px;
    border-radius: 356px;
    background: black;
    color: white;
  }
  .text {
    position: relative;
    z-index: 1;
    color: white;

    font-family: Roboto-Bold;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.06px;
    text-align: center;
    cursor: pointer;
  }
  .wave {
    width: 200px;
    height: 200px;
    background-color: #0096ea;
    position: absolute;
    left: 0;
    top: -83px;
    transition: 2.4s;
  }
  .wave::before,
  a .wave::after {
    width: 200%;
    height: 200%;
    content: "";
    position: absolute;
    top: 0;
    left: 30%;
    transform: translate(-50%, -50%);
  }
  .d-flex-circle-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 40px 40px;
  }
}
@media screen and (max-width: 560px) {
  .d-flex-circle-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 40px 40px;
  }
  .water-purefire img {
    /* max-width: 515.46px;
    min-width: 250px; */
    width: 350px;
    /* height: 550px; */
    position: relative;
    z-index: 1;
  }
  .water-purefire .water-ro {
    position: absolute;
    top: 85px;
    left: 0;
    opacity: 1;
    z-index: 0;
    /* max-width: 515.46px;
    min-width: 250px; */
    width: 350px;
    /* padding-top: 74px; */
  }
}
@media screen and (max-width: 380px) {
  .water-purefire img {
    /* max-width: 515.46px;
    min-width: 250px; */
    width: 250px;
    /* height: 550px; */
    position: relative;
    z-index: 1;
  }
  .water-purefire .water-ro {
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 1;
    z-index: 0;
    /* max-width: 515.46px;
    min-width: 250px; */
    width: 250px;
    /* padding-top: 74px; */
  }
}
